export default {
  title: "Paramètres de calcul",
  financing: {
    title: "Solutions de financement",
    "learn-more": "En savoir plus",
    types: {
      "all-equity": "Fonds propres",
      loan: "Avec crédit",
      "roof-lease": "Avec location",
      ppa: "Avec PPA",
      "loan-input": {
        inputs: "Paramètres du crédit",
        "equity-share": "Montant au comptant",
        "debt-share": "Montant du crédit",
        duration: "Durée du crédit (années)",
        "interest-rate": "TAEG fixe",
        "insurance-fee": {
          label: "Frais d'assurance du crédit",
          description:
            "Coûts de traitement du dossier pour l’obtention de l’assurance du crédit.",
        },
        "underwriting-fee": {
          label: "Frais de souscription",
          description:
            "Coûts représentant les frais annexes, dont le titre de l’assurance du crédit.",
        },
        "origination-fee": {
          label: "Frais de courtage",
          description:
            "Coûts de commissions sur la transaction pour le service d'intermédiation, versée au courtier.",
        },
        "application-fee": {
          label: "Frais de dossier",
          description:
            "Coûts de traitement du dossier pour l’obtention du crédit.",
        },
        "appraisal-fee": {
          label: "Frais d'évaluation",
          description:
            "Coûts pour le déplacement puis valorisation du bien, versée à l’expert.",
        },
      },
      "ppa-input": {
        "escalation-rate": "Escalation rate",
      },
      "lease-input": {},
      "roof-lease-input": {},
    },
  },
  costs: {
    title: "Coûts",
    "enedis-construction-cost": {
      label: "Coûts des travaux d'Enedis",
      description:
        "Coûts supplémentaires de renforcement du réseau électrique en BT ou HTA par le gestionnaire du réseau.",
    },
    "insurance-cost-annual": {
      label: "Coûts annuels de(s) assurance(s)",
      description:
        "L’installation photovoltaïque peut engendrer une extension d'une ou plusieurs assurance(s) du bien, ainsi que d’autres frais d’assurance.",
    },
    "maintenance-cost-annual": {
      label: "Coûts annuels d’entretien",
      description:
        "Un contrôle annuel de l'installation photovoltaïque est recommandé. L’entretien inclut le nettoyage et la maintenance éventuelle.",
    },
    "quality-control-cost": {
      label: "Coût de contrôle de qualité",
      description:
        "Un contrôle de qualité après la mise en service de l'installation photovoltaïque est recommandé. Pas obligatoire, contrairement à l'attestation de conformité du Consuel.",
    },
  },

  "additional-inputs": {
    title: "Paramètres supplémentaires",
    "income-tax-rate": {
      label: "Taux d’imposition sur revenus",
      description:
        "La vente de surplus est imposable, contrairement aux économies sur facture, et réduit donc les revenus réels.",
    },
    "inflation-rate": {
      label: "Taux d’inflation",
      description:
        "Taux calculé par la B.C.E. pour prendre en compte la valeur réelle de l’argent dans le temps, une hypothèse pessimiste.",
    },
    "discount-rate": {
      label: "Taux d'actualisation",
      description:
        "Taux applicable aux flux de trésorerie pour prendre en compte la valeur réelle de l’argent dans le temps, une hypothèse pessimiste.",
    },
    "disable-virtual-battery": "Désactiver la batterie virtuelle",
  },
  "read-more-about-the-inputs": "En savoir plus sur les paramètres",
}
